<template>
  <div class="video-boxs">

    <img class="imgs-con" :width="videodata.width" :height="videodata.height" :id="`img${videodata.id}`"
      :src="videodata.preview" />
    <div :id="`video-box${videodata.id}`" class="video-box" v-if="videoPl">
      <video :ref="`video${videodata.id}`" :id="`video${videodata.id}`" class="product-video" muted="muted"
        preload="none">
        <source :src="videodata.small_preview" type="video/mp4" />
      </video>
    </div>
    <span class="time">{{ videodata.duration | formatTime }}</span>
    <div class="conten-box" @click.stop="topath(videodata)" @mouseover="productEnter(videodata)"
      @mouseout="productLeave(videodata)">

    </div>
    <div class="image-item-shadowbox">
      <el-tooltip content="收藏" placement="top" popper-class="card-tooltip" v-if="!videodata.is_favorite">
        <div class="cardiconbox iconbox-collect" @click.stop="addCollect(videodata)">
          <img class="init" src="@/assets/image/common/imagecollect.png" alt="" />
        </div>
      </el-tooltip>
      <el-tooltip content="取消收藏" placement="top" popper-class="card-tooltip" v-if="videodata.is_favorite">
        <div class="cardiconbox iconbox-collect" @click.stop="delCollect(videodata)">
          <img class="init" src="@/assets/image/common/imagecollectcheck.png" />
        </div>
      </el-tooltip>
    </div>
    <div class="image-item-shadowbox-title">{{videodata.name}}</div>
    <collect-dialog v-if="collect.visible" :moveIds="collect.moveIds" :moveFold="false" :category="2"
      @close="closeCollect" />
  </div>
</template>

<script>
import CollectDialog from "@/components/collectDialog";
import $ from "jquery";
export default {
  name: "videoCard",
  props: ["videodata"],
  components: { CollectDialog, },
  data () {
    return {
      collect: {
        visible: false,
        moveIds: "",
      },
      downloading: false,
      videoPl: false,
    };
  },
  created () {
  },
  computed: {},
  mounted () {
  },
  methods: {
    topath (data) {
      this.$emit("func", data);
    },
    // 视频鼠标移入播放
    productEnter (data) {
      this.videoPl = true;
      let style = $("#img" + data.id).attr("style");

      setTimeout(() => {
        $("#video-box" + data.id).attr("style", style);
        if (document.getElementById("video" + data.id)) {
          let video = document.getElementById("video" + data.id);
          video.currentTime = 0;
          let playPromise = video.play();
          if (playPromise !== undefined) {
            playPromise
              .then((_) => {
                video.addEventListener("timeupdate", () => {
                  if (parseInt(video.currentTime) >= video.duration) {
                    video.currentTime = 0;
                    video.pause();
                  }
                });
              })
              .catch((err) => { });
          }
        }
      }, 300)
    },
    // 视频鼠标移出暂停
    productLeave (data) {
      this.videoPl = false;
      if (document.getElementById("video" + data.id)) {
        let video = document.getElementById("video" + data.id);
        video.load();
      }
    },
    // 添加收藏
    addCollect (item) {
      if (!this.staticFunc.isLogin()) {
        this.$store.commit("watchShowLogin", true);
        return;
      }
      this.$route.meta.index = 0;
      this.collect.moveIds = item.editor_id;
      this.collect.visible = true;
      return false;
    },
    // 取消收藏
    delCollect (item) {
      this.$bus.$emit('delVideoCollect', item);
    },
    // 收藏成功
    closeCollect (refresh) {
      if (refresh) {
        this.videodata.is_favorite = true;
      }
      this.collect.visible = false;
    },
  },
  filters: {
    formatTime (time) {
      // 格式化毫秒，返回String型分秒对象
      // 有可能没获取到，为NaN
      if (!time) return "00:00";
      let min;
      let sec;
      min = Math.floor(time / 60)
        .toString()
        .padStart(2, "0");
      sec = Math.floor(time % 60)
        .toString()
        .padStart(2, "0");
      return min + ":" + sec;
    },
  },
};
</script>

<style lang="scss" scoped>
.imgs-con {
  opacity: 0;
}

.video-boxs {
  border-radius: 12px;
  background: #D8D8D8;
  overflow: hidden;
  cursor: pointer;

  .conten-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .video-box {
    position: absolute;
    left: 50%;
    top: 50%;

    .product-video {
      width: 100%;
      height: 100%;
    }
  }

  .time {
    position: absolute;
    bottom: 12px;
    right: 12px;
    width: 43px;
    height: 20px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
    font-family: Helvetica;
    color: #ffffff;
  }

  &:hover {
    .image-item-shadowbox {
      display: block;
    }

    .image-item-shadowbox-title {
      display: block;

    }
  }

  .image-item-shadowbox {
    display: none;
    height: 44px;
    position: absolute;
    top: 0;
    right: 0;

    .cardiconbox {
      position: absolute;
      top: 12px;
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 2px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
    }

    .iconbox-collect {
      right: 12px;
      background: none;

      img {
        width: 100%;
        height: 100%;
        padding: 0;
        background: none;
      }
    }
  }

  .image-item-shadowbox-title {
    text-align: left;
    line-height: 44px;
    color: white;
    display: none;
    height: 44px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 44px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5600) 100%);
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 70px;
    padding-left: 10px;
    white-space: nowrap;
    pointer-events: none;
  }
}
</style>
